<template>
    <div class="custom-input">
        <label v-if="$validate.DataValid(label)" class="input-label">{{ label }}</label>
        <v-text-field 
            outlined single-line
            :dense="dense"
            :value="fieldValue" 
            :autofocus="autofocus"  
            :disabled="disabled" 
            :readonly="isReadOnly"
            :hide-details="hideDetails"
            :placeholder="placeholder" 
            :type="inputType"
            :rules="$validate.DataValid(rules[type]) ? rules[type] : rules.text" 
            @keydown.enter="isSearch ? clickSearch() : null"
            @keypress="formatInput($event)"
            @input="updateValue($event)"
            @blur="onBlur"
            :prefix="prefix"
            :suffix="suffix"
            :class="inputClass"
            autocomplete="new-password"
            @click:clear="onClear()"
            :clearable="enabledClear"
        >
            <template v-slot:append>
                <v-icon v-if="isSearch" @click.prevent="clickSearch">{{ icon.mdiMagnify }}</v-icon>
                <template v-if="type === 'password' && showPwView">
                    <v-btn v-if="inputType === 'text'" icon text solo dense v-ripple="false" @click.prevent="inputType = 'password'">
                        <v-icon class="fi fi-rr-eye" size="20" ></v-icon>
                    </v-btn>
                    <v-btn v-else-if="inputType === 'password'" icon text solo dense v-ripple="false" @click.prevent="inputType = 'text'">
                        <v-icon class="fi fi-rr-eye-crossed" size="20" ></v-icon>
                    </v-btn>
                </template>
            </template>
            <template v-slot:append-outer>
                <slot name="append-outer"></slot>
            </template>
            <template v-slot:prepend-inner>
                <slot name="prepend-inner"></slot>
            </template>
            
        </v-text-field>
    </div>
</template>

<script>
import { mdiMagnify } from '@mdi/js'

export default {
    name: 'FormInput',
    props: {
        label: {
            type: String,
            required: false,
            default: ''
        },
        fieldValue: {
            type: String | Number,
            required: true,
            default: ''
        },
        type: {
            type: String,
            required: false,
            default: 'text'
        },
        autofocus: {
            type: Boolean,
            required: false,
            default: false
        },
        hideDetails: {
            type: Boolean,
            required: false,
            default: false
        },
        required: {
            type: Boolean,
            required: false,
            default: false
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false
        },
        placeholder: {
            type: String,
            required: false,
            default: ''
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        customRules: {
            type: Array,
            required: false,
            default: () => [],
        },
        isIntegerOnly: {
            type: Boolean,
            required: false,
            default: false
        },
        isEngNameOnly: {
            type: Boolean,
            required: false,
            default: false
        },
        isSearch: {
            type: Boolean,
            required: false,
            default: false
        },
        isSearchId: {
            type: Boolean,
            required: false,
            default: false
        },
        dense: {
            type: Boolean,
            required: false,
            default: false
        },
        prefix: {
            type: String,
            required: false,
            default: ''
        },
        suffix: {
            type: String,
            required: false,
            default: ''
        },
        customInputClass: {
            type: String,
            required: false,
            default: ''
        },
        showPwView: {
            type: Boolean,
            required: false,
            default: false
        },
        dispatchUpdateOnChange: {
            type: Boolean,
            required: false,
            default: false
        },
        enabledClear: {
            type: Boolean,
            required: false,
            default: false
        },
    },
    computed: {
        rules() {
            return {
                text: [
                    ...this.customRules,
                    v => {
                        return this.isEngNameOnly ? this.$validate.isEngName(v, !this.required) : true
                    },
                    v => {
                        return this.required ? (this.$validate.required(v) || '此位置不能留空') : true
                    },
                ],
                number: [
                    ...this.customRules,
                    v => {
                        return this.isIntegerOnly ? this.$validate.isNumber(v, !this.required) : true
                    },
                    v => {
                        return this.$validate.isFloating(v, !this.required)
                    },
                    v => {
                        return this.required ? this.$validate.required(v) || '此位置不能留空' : true
                    },
                ],
                tel: [
                    ...this.customRules,
                    v => {
                        return this.$validate.isPhoneNumber(v, !this.required)
                    },
                    v => {
                        return this.required ? this.$validate.required(v) || '此位置不能留空' : true
                    },
                ],
                email: [
                    ...this.customRules,
                    v => {
                        return this.$validate.isEmail(v, !this.required)
                    },
                    v => {
                        return this.required ? this.$validate.required(v) || '此位置不能留空' : true
                    },
                ],
            };
        },
        isReadOnly() {
            return this.readonly || this.$store.getters.isLoading
        },
        inputClass() {
            let classObj = {
                'pointer-none': this.$store.getters.isLoading,
                'pw-view': this.type === 'password' && this.showPwView
            };
            if (this.$validate.DataValid(this.customInputClass)) {
                const arr = this.customInputClass.split(' ');
                arr.forEach(name => {
                    if (this.$validate.DataValid(name.trim())) {
                        classObj[name.trim()] = true;
                    }
                })
            }

            return classObj;
        }
    },
    data: () => ({
        icon: {
            mdiMagnify
        },
        searchText: '',
        inputType: 'text',
    }),
    watch: {
        type: {
            handler(val) {
                if (val === 'password') {
                    this.inputType = 'password';
                }
            },
            immediate: true
        },
    },
    methods: {
        formatInput(event) {
            if (this.isIntegerOnly) {
                return this.$formatter.number(event)
            } else if (this.isSearchId) {
                return this.$formatter.id(event)
            } else if (this.isEngNameOnly) {
                return this.$formatter.engName(event)
            } else if (this.type === 'tel') {
                return this.$formatter.phone(event)
            } else if (this.type === 'number') {
                return this.$formatter.floating(event)
            } 
        },
        updateValue(value) {
            if (value === null) {
                this.searchText = '';
            } else {
                this.searchText = value;
            }
          
            this.$emit('update:fieldValue', this.searchText);
            this.$emit('input-value', this.searchText);
            if (this.dispatchUpdateOnChange === true) {
                this.$store.dispatch('setDataIsUpdated', true);
            }
        },
        onClear() {
            this.searchText = '';
            this.$emit('update:fieldValue', '');
            this.$emit('clear-value', '');
        },
        onBlur(ev) {
            this.$emit('onblur', ev);
        },
        clickSearch() {
            this.$emit('search-click', this.searchText);
        },
    }
}
</script>

<style lang="scss" scoped>
::v-deep .pw-view .v-input__append-inner {
    margin: 0 !important;
    
}
</style>